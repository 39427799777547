import React from "react";

export default function Footer() {
    return (
        <footer id="footer" className="footer">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <img src="./assets/img/new_logo.png" alt="logo" />

                            </a>

                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Links</h4>
                            <ul>
                                {/* <li><i className="bi bi-chevron-right"></i> <a href="#hero">Home</a></li> */}
                                <li><i className="bi bi-chevron-right"></i> <a href="#about">About us</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services">Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#faqs">FAQs</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#contact">Contact</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services">Software Development Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services">Application Development</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services">Web Development</a></li>

                            </ul>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Novisync Digital</span></strong>. All Rights Reserved {new Date().getFullYear()}
                </div>
                {/* <div className="credits">
                    Designed by <a target="_blank" href="https://novisync.com/">Novisync Team</a>
                </div> */}
            </div>
        </footer>
    )
}