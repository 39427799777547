import React from "react";
import Footer from "./Components/Footer";
import TopNav from "./Components/Topnav";
import Hero from "./Screens/Hero";
import Main from "./Screens/Main";


function App() {
  return (
    <div>
      <TopNav />
      {/* <Hero /> */}
      <Main />
      <Footer />
    </div>
  );
}

export default App;
