import React, { useEffect, useState } from "react";
import AOS from 'aos'
import { isMobile } from "react-device-detect";
import { SERVER_URL } from "../config";


export default function Main() {

    useEffect(() => {
        AOS.init()
    }, []);

    const [data, setData] = useState({})
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState(null)

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        setMessage()
        await fetch(
            `${SERVER_URL}/contactUsRequest`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setMessage('success')
                setData({
                    name: '',
                    email: '',
                    mobile: '',
                    message: ''
                })

            })
            .catch((error) => {
                setMessage('error')
                setLoader(false)
            });
        setLoader(false)
    }

    return (
        <main id="main">
            <section id="about" style={{ height: 'auto' }} className="hero d-flex align-items-center">

                <div className="container" >
                    <div className="row align-items-center">
                        <div className="col-lg-12 d-flex flex-column justify-content-center">
                            <div style={{ marginTop: isMobile ? 0 : 50 }}>
                                <h4 style={{ color: '#A24857' }} data-aos="fade-up" data-aos-delay="400" className="text-start text-bold">Leverage Information technology to take your business and ideas to next level</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <br />
                            <br />
                            <div data-aos="fade-up" data-aos-delay="600" >
                                <div className="content">
                                    {/* <!-- <h3>Who We Are</h3> --> */}
                                    <h5 className="text-start text-primary text-bold">Unlock Your Business Potential with Custom  Software Solutions</h5>
                                    <p className="text-start "> Novisync Digital team understands the challenges that small and medium businesses face in today's rapidly evolving digital landscape. Your success is our priority, and that's why we offer customized software that helps to drive your business growth.</p>
                                    <h5 className="text-primary text-start text-bold">Our Commitment: Your Success</h5>
                                    <p className="text-start">
                                        We are not just a software development company – we are your trusted partner on the journey to achieving your business goals. Our team of experienced developers, architects, and experts collaborates closely with you to create solutions that address your specific needs, streamline operations, and enhance customer experiences.
                                    </p>
                                    <p className="text-start">Discover how our custom software solutions can transform your business. Contact us today to explore the possibilities.</p>
                                    {/* <!-- <div className="text-center text-lg-start">
            <a href="#contact" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
              <span>Connect with us</span>
              <i className="bi bi-arrow-right"></i>
            </a>
          </div> --> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/about-bg.png" class="img-fluid" alt="" />

                        </div>
                    </div>



                </div>

            </section>


            <section id="services" className="values">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <p>Our Services</p>
                    </header>

                    <div className="row">

                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="box">
                                <img src="./assets/img/values-1.png" className="img-fluid" alt="" />
                                <h3>Software Development Services</h3>
                                <p>Your Unique Software Solution</p>
                                <p>We create custom software that fits your business perfectly, using the latest technology and best practices.</p>
                                <p>Let's talk to learn about your business challenges and let us find the perfect solution.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                            <div className="box">
                                <img src="./assets/img/values-2.png" className="img-fluid" alt="" />
                                <h3>Application Development</h3>
                                <p>Seize the opportunity to elevate your business with a custom app designed and developed to meet your unique needs.</p>
                                <p>Reach out today to arrange a free consultation and discover how we can assist you.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                            <div className="box">
                                <img src="./assets/img/values-3.png" className="img-fluid" alt="" />
                                <h3>Web Development</h3>
                                <p>Step into our inclusive bespoke web development services in the USA, designed for businesses of every kind and magnitude. Our skilled team of designers, and developers collaborate to craft your website or web app with precision. With our proficient guidance, your custom website development requirements are secure. Get in touch now to explore how we can elevate your business journey.</p>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            <section id="faqs" className="contact">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <p>FAQs</p>
                    </header>

                    <div className="row gy-4">
                        <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What are the perks of working with Novisync Digital?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul>
                                                <li>
                                                    <p><b>Collaboration</b></p>
                                                    <p>We focus on team collaboration and transparency with our customers because the key to our company's success is synergy and trust between them.</p>
                                                </li>
                                                <li>
                                                    <p><b>Ownership</b></p>
                                                    <p>We own every task. This approach allows us to eliminate chaos, makes our processes efficient, and gives everyone a clear path to growth together.</p>
                                                </li>
                                                <li>
                                                    <p><b>Result-driven</b></p>
                                                    <p>We are deeply engaged in what we do as we love to deliver results with fun and pleasure.</p>
                                                </li>
                                                <li>
                                                    <p><b>Development</b></p>
                                                    <p>We invest our time in self-education and training, and this helps us to stay current and add freshness in our common development.</p>
                                                </li>
                                                <li>
                                                    <p><b>Professional Second Opinion</b></p>
                                                    <p>Audit your software to boost its performance and improve user experience.</p>
                                                </li>
                                                <li>
                                                    <p><b>Valuable Market Insights</b></p>
                                                    <p>Get the latest tech follow-up, including market trends and users’ needs.</p>
                                                </li>
                                                <li>
                                                    <p><b>On-time and On-budget Delivery</b></p>
                                                    <p>We guarantee a record 10% deviation in time and scope for project delivery.</p>
                                                </li>
                                                <li>
                                                    <p><b>Software Support After the Release</b></p>
                                                    <p>We provide ongoing maintenance and Quality Assurance for your software.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What software development services do you provide?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We offer the next set of software and design development services separately or within the full project development life cycle:</p>
                                            <ul>
                                                <li>
                                                    <p><b>Branding and UI/UX Design</b></p>
                                                    <p>Create compelling branding and intuitive user experiences to enhance your product's visual appeal and usability.</p>
                                                </li>
                                                <li>
                                                    <p><b>Web Development</b></p>
                                                    <p>Develop responsive and feature-rich websites tailored to your business needs, ensuring a seamless online presence.</p>
                                                </li>
                                                <li>
                                                    <p><b>Mobile App Development</b></p>
                                                    <p>Build innovative and user-friendly mobile applications that provide value to your users on various platforms.</p>
                                                </li>
                                                <li>
                                                    <p><b>Product Management</b></p>
                                                    <p>Efficiently manage the entire lifecycle of your product, from ideation to launch and beyond, ensuring its success.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What collaboration models do you offer?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We are very flexible on our collaboration models and our clients can choose one or mix them from the following:</p>
                                            <p><b>Part-time</b></p>
                                            <ul>
                                                <li>
                                                    <p>Our specialists are assigned to a few projects at the same time.</p>
                                                </li>
                                                <li>
                                                    <p>The team or a single specialist reports directly to your Head of Project and management.</p>
                                                </li>
                                                <li>
                                                    <p>Our Head of Project Management Office is responsible for the smooth process and performance.</p>
                                                </li>
                                                <li>
                                                    <p>Both Hourly Rate and Fixed pricing models are applicable.</p>
                                                </li>
                                            </ul>
                                            <p><b>Full-time</b></p>
                                            <ul>
                                                <li>
                                                    <p>Our specialists are assigned only to your project.</p>
                                                </li>
                                                <li>
                                                    <p>The team or a single specialist reports directly to your Head of Project and management.</p>
                                                </li>
                                                <li>
                                                    <p>Our Head of Project Management Office is responsible for the smooth process and performance.</p>
                                                </li>
                                                <li>
                                                    <p>We provide available specialists or hire additional ones for your project if needed.</p>
                                                </li>
                                            </ul>
                                            <p><b>Time & Material</b></p>
                                            <ul>
                                                <li>
                                                    <p>Quick start due to the already existing team setup.</p>
                                                </li>
                                                <li>
                                                    <p>The team is managed by us and supervised by you.</p>
                                                </li>
                                                <li>
                                                    <p>Pay only for time spent on the development process.</p>
                                                </li>
                                            </ul>
                                            <p><b>Team Extension</b></p>
                                            <ul>
                                                <li>
                                                    <p>Provide a list of requirements for a specialist.</p>
                                                </li>
                                                <li>
                                                    <p>Our team of recruiters will search and interview the candidates.</p>
                                                </li>
                                                <li>
                                                    <p>We assure the onboarding and full administrative support.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                                            What are the advantages of using our team?
                                        </button>
                                    </h2>
                                    <div id="collapse4" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul>
                                                <li>
                                                    <p><b>More Time to Stay Focused on Business Development</b></p>
                                                    <p>Outsourcing your projects to us will grant you enough time to focus on growing your company as we carry out our services in the most efficient way for your business and without the need to involve you too much in this process.</p>
                                                </li>
                                                <li>
                                                    <p><b>High Professional Development Team</b></p>
                                                    <p>With our developers' team, you are guaranteed highly experienced IT professionals at your disposal. For your project, we will hire only those engineers who meet your project requirements.</p>
                                                </li>
                                                <li>
                                                    <p><b>The Product-Oriented Approach in Software Development</b></p>
                                                    <p>While providing our software development services, we focus our efforts on your business goals and how our digital solutions will meet them.</p>
                                                </li>
                                                <li>
                                                    <p><b>Control Over Your IT Costs</b></p>
                                                    <p>Outsourcing your IT projects will also reduce your running cost as you have full control over your IT costs. We will set a fixed price for our service so as to help you adequately allocate your budget.</p>
                                                </li>
                                            </ul>  </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                                            How long does it take to start my product development?
                                        </button>
                                    </h2>
                                    <div id="collapse5" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul>
                                                <li>
                                                    <p>To initiate the process, we begin by reviewing the project requirements provided by you. We thoroughly analyze these requirements and subsequently present you with a proposed user experience (UX) design. This process continues until the acceptance criteria for the project are firmly established.</p>
                                                </li>
                                                <li>
                                                    <p>Upon mutual agreement regarding the desired outcomes based on the defined acceptance criteria, we proceed to communicate the estimated time and cost involved. Once you confirm your satisfaction with the projected time and cost, the subsequent step involves the creation of a comprehensive project report and proposal.</p>
                                                </li>
                                                <li>
                                                    <p>It's important to note that you will not incur any costs during the stages of requirement gathering and design. The cost consideration will only come into effect when the development process is initiated.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                                            What does your software development process look like?
                                        </button>
                                    </h2>
                                    <div id="collapse6" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                A product development process stands for the multiple stages that a software development team takes to bring the idea of a product into reality by undergoing software product development steps.
                                            </p>
                                            <p>Thus, we rely on the software development life cycle (SDLC). The procedure implies building high-quality software within the shortest possible period, in line with the 7 phases of the software development life cycle.
                                            </p>
                                            <p><b>The development life cycle phases involves:</b></p>
                                            <ul>
                                                <li>
                                                    <p>the assessment of the user’s needs</p>
                                                </li>
                                                <li>
                                                    <p>research on customer behavior and marketplace competition</p>
                                                </li>
                                                <li>
                                                    <p>creation and visualization of the roadmap</p>
                                                </li>

                                                <li>
                                                    <p>concept design</p>
                                                </li>
                                                <li>
                                                    <p>code development</p>
                                                </li>
                                                <li>
                                                    <p>creation of a minimum viable product</p>
                                                </li>
                                            </ul>
                                            <p>With a seamless and well-designed SDLC process, we don’t miss a thing and find the balance and the best possible solutions between different actions while developing software.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                                            What software development methodologies do we use?
                                        </button>
                                    </h2>
                                    <div id="collapse7" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Our professionals use a wide range of SDLC methodologies for creating apps: from the Waterfall Model to the Big Bang Model. We picked the best of all the methodologies and created a platform that gives you access to what developers are working on, how long they are taking, and are stuck with roadblocks. We value time and be as efficient as possible to take your product to market and keep the transparent communication among us. </p>
                                            <p>We document all the operations required to handle the development of your digital product and measure them according to the following key parameters:    </p>
                                            <ul>
                                                <li>
                                                    <p>Task description</p>
                                                </li>
                                                <li>
                                                    <p>Milestone documentation</p>
                                                </li>
                                                <li>
                                                    <p>Acceptance Criteria management</p>
                                                </li>
                                                <li>
                                                    <p>Organizational assets</p>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                                            What control do you have over project development?
                                        </button>
                                    </h2>
                                    <div id="collapse8" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                You would retain full control over all stages in the development life cycle of your project. You can monitor the process through regular calls and meetings, track the status and exercise of every task.</p>
                                            <p>
                                                Within a set of meetings scheduled throughout the whole development cycle, we would inform you of the progress in regard to the project roadmap. We divide our entire work into sprints for ease of tracking and so you can make changes in the scope at any stage.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                                            How do we assure privacy and confidentiality?
                                        </button>
                                    </h2>
                                    <div id="collapse9" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                We sign NDAs and Confidentiality Agreements as required by the client. All our employees work for us full-time and are bound by company Confidentiality and Non-Disclosure clauses.
                                            </p>
                                            <p> Additionally, for sensitive projects, we can sign custom NDAs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapseThree">
                                            How do we comply with Intellectual Property issues?
                                        </button>
                                    </h2>
                                    <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We have developed our own Service Agreement and NDAs and have signed them with all our development teams. The agreements ensure the confidentiality and the full transfer of ownership of the intellectual property from the developer to the company. After you decide to outsource your project to us, we sign up for a software outsourcing agreement that transfers all IP rights to your company.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapseThree">
                                            How do we ensure security and risk management of your product?
                                        </button>
                                    </h2>
                                    <div id="collapse11" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>When you outsource your projects to our company, we not only provide software development services but manage all possible compliance and security issues.</p>
                                            <p>Our specialists evaluate all types of threats before creating your digital product including confidentiality weak points, IP compliance, third-party integrations, data management, and others. This approach allows us to discover the possible risks on the prospecting stage and create the appropriate policies to prevent them.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapseThree">
                                            How do you guarantee product quality?
                                        </button>
                                    </h2>
                                    <div id="collapse12" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We evaluate the result after every two weeks, we test our work (we conduct both development and acceptance tests), we present it to you, we ask for the client’s feedback so the clients know they get what they are paying for. Also, we have continuous communication with the client, so that there are no surprises and we move forward in the development only after the client’s approval.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section >
            <section id="contact" className="contact">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        {/* <!-- <h2>Contact</h2> --> */}
                        <p>Submit a request and we’ll get in touch within 60 Minutes</p>
                    </header>

                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <form onSubmit={(e) => handleSubmit(e)} id="form">
                                <div className="php-email-form">
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <input type="text" value={data?.name} name="name" className="form-control" placeholder="Your Name" required onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input type="email" value={data?.email} className="form-control" name="email" placeholder="Your Email" required onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="tel" id="phone" value={data?.mobile} name="mobile" className="form-control" placeholder="Mobile Number" required onChange={(e) => handleChange(e)} />

                                        </div>
                                        <div className="col-md-12">
                                            <textarea className="form-control" value={data?.message} name="message" rows="6" placeholder="Tell us more about your project & needs" required onChange={(e) => handleChange(e)}></textarea>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            {loader &&
                                                <div className="loading">Loading</div>
                                            }
                                            {
                                                message === 'error' &&
                                                <div className="error-message"></div>
                                            }
                                            {
                                                message === 'success' &&
                                                <div className="sent-message ">Your message has been sent. Thank you!</div>
                                            }
                                            <br />
                                            <button type="submit">Submit Request</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>

                        <div className="col-lg-6">

                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>New York Address</h3>
                                        <p>300 Westage Business Center Drive</p>
                                        <p>Suite #400</p>
                                        <p>Fishkill, NY, USA - 12524.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>Dallas Address</h3>
                                        <p>7300 State Hwy 121 Ste 300</p>
                                        <p>McKinney</p>
                                        <p>TX 75070.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-telephone"></i>
                                        <h3>Call Us</h3>
                                        <p>+1 845 765 0250</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-envelope"></i>
                                        <h3>Email Us</h3>
                                        <p><a href="mailto:contact@novidigi.com">Email: contact@novidigi.com</a></p>
                                    </div>
                                </div>
                                {/* <!-- <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-clock"></i>
                                        <h3>Open Hours</h3>
                                        <p>Monday - Friday<br>9:00AM - 05:00PM</p>
                                    </div>
                                </div> --> */}
                            </div>

                        </div>

                    </div>

                </div>

            </section >
        </main >
    )
}