import React, { useState } from "react";
import Modal from 'react-modal';
import { isMobile } from "react-device-detect";

const customStyles = {
    content: {
        top: isMobile ? '30%' : '30%',
        left: isMobile ? '50%' : '85%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {zIndex: 10}
};

Modal.setAppElement('#root');

export default function TopNav() {
    console.log(isMobile)
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(!modalIsOpen);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <header id="header" className="header header-scrolled fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <a href="/" className="logo d-flex align-items-center">
                    <img src="./assets/img/new_logo.png" alt="logo" />

                </a>

                <nav id="navbar" className="navbar">
                    <div style={{ width: '40px', height: '40px', cursor: 'pointer' }} onClick={() => openModal()}>
                        <svg className="gb_j" focusable="false" viewBox="0 0 24 24"><path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                    </div>
                </nav>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => closeModal()}
                style={customStyles}
                contentLabel="Novisync Digital"
            >
                <div>
                    <div class="menu-options">
                        {/* <a href="#about" onClick={() => setIsOpen(false)} className="option"><i class="bi bi-house"></i> Home</a> */}
                        <a href="#about" onClick={() => setIsOpen(false)} className="option"><i class="bi bi-info-circle"></i> About</a>
                        <a href="#services" onClick={() => setIsOpen(false)} className="option"> <i class="bi bi-building-gear"></i>Services</a>
                        <a href="#faqs" onClick={() => setIsOpen(false)} className="option"><i class="bi bi-question-octagon"></i> FAQs</a>
                        <a href="#contact" onClick={() => setIsOpen(false)} className="option"><i class="bi bi-telephone"></i> Contact</a>
                    </div>

                </div>
            </Modal>
        </header>
    )
}